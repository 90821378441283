// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgInsights = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.5 7.5.783-1.725L15 5l-1.717-.775L12.5 2.5l-.767 1.725L10 5l1.733.775zm5.003-.833c-1.209 0-1.884 1.2-1.609 2.092l-2.958 2.966a1.7 1.7 0 0 0-.867 0L9.944 9.6c.284-.891-.391-2.1-1.608-2.1-1.208 0-1.892 1.2-1.608 2.1l-3.8 3.792c-.892-.275-2.092.4-2.092 1.608 0 .917.75 1.667 1.667 1.667 1.208 0 1.883-1.2 1.608-2.092l3.792-3.8c.25.075.616.075.866 0l2.125 2.125c-.283.892.392 2.1 1.609 2.1 1.208 0 1.891-1.2 1.608-2.1l2.967-2.958c.891.275 2.091-.4 2.091-1.608 0-.917-.75-1.667-1.666-1.667M3.336 7.5l-.417 1.667L2.503 7.5.836 7.083l1.667-.416L2.919 5l.417 1.667 1.667.416z"
    />
  </svg>
);
export const InsightsIcon = forwardRef(SvgInsights);
